var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("n-page", [
    _c(
      "div",
      [
        _c(
          "a-card",
          { staticClass: "gx-card-full", attrs: { title: _vm.schedules } },
          [
            _c("vue-cal", {
              staticStyle: { height: "600px" },
              attrs: {
                xsmall: "",
                "time-from": 1 * 60,
                "time-to": 24 * 60,
                "time-cell-height": 40,
                "default-view": "week",
                events: _vm.events,
                "time-step": 20,
                "on-event-click": _vm.onEventClick,
                "disable-views": ["years"],
                "selected-date": _vm.selectedDate,
              },
            }),
            _c(
              "a-modal",
              {
                model: {
                  value: _vm.showDialog,
                  callback: function ($$v) {
                    _vm.showDialog = $$v
                  },
                  expression: "showDialog",
                },
              },
              [
                _c(
                  "template",
                  { slot: "footer" },
                  [
                    _c(
                      "a-button",
                      {
                        key: "back",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.showDialog = false
                          },
                        },
                      },
                      [_vm._v("\n            Close\n          ")]
                    ),
                  ],
                  1
                ),
                _c("h2", [_vm._v(_vm._s(_vm.selectedEvent.title))]),
                _c("a-divider"),
                _c("strong", [
                  _vm._v(_vm._s(_vm.nFormat(_vm.selectedEvent.startDate))),
                ]),
                _c("strong", [_vm._v("Event details:")]),
                _c("ul", [
                  _c("li", [
                    _vm._v("Location: " + _vm._s(_vm.selectedEvent.location)),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Event starts at: " + _vm._s(_vm.selectedEvent.startTime)
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Event ends at: " + _vm._s(_vm.selectedEvent.endTime)
                    ),
                  ]),
                  _c("li", [
                    _vm._v("Cost: €" + _vm._s(_vm.selectedEvent.cost)),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }